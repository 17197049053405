<template>
    <el-form-item>
        <el-input
            v-model="noteForEmployeeEdit"
            type="textarea"
            :placeholder="$t('calendar.note_for_employee')"
            resize="none"
            :autosize="{
                minRows: 5
            }"
            @change="onChange"
        />
    </el-form-item>
</template>

<script>
export default {
    props: {
        noteForEmployeeProp: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            noteForEmployeeEdit: this.noteForEmployeeProp,
        };
    },

    computed: {
        noteForEmployee() {
            return this.noteForEmployeeProp;
        },
    },

    methods: {
        onChange() {
            this.$emit('onChange', this.noteForEmployeeEdit);
        },
    },

};
</script>
